import * as React from 'react';

import { Page, flexgutter } from '../components/common';

import { Helmet } from 'react-helmet';
import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from '@emotion/styled';

type Props = {
  path: string;
};

const Privacy = styled.div`
  ${flexgutter}
`;

const TosPage: React.FC<Props> = ({ path }: Props) => (
  <Layout path={path}>
    <SEO title="terms of service" />
    <Helmet />
    <Privacy>
      <Page>
        <div>
          <h3>Terms of Service</h3>
          <p>Last Updated: Nov 11, 2021</p>
          <p>
            Please read these Terms of Service. You agree to comply and be bound
            by these Terms of Service by accessing or using the Silofit
            Platform.
          </p>
          <p>
            These Terms of Service (“<strong>Terms</strong>”) constitute a
            legally binding agreement between you and Silofit Enterprises (“
            <strong>Silofit</strong>”, “<strong>we</strong>” or “
            <strong>us</strong>”). These Terms govern your access to and use of
            the Silofit Spaces (as defined below), the Silofit website and any
            other sites through which Silofit makes the Silofit Services
            available (collectively, the “<strong>Site</strong>”), our mobile,
            tablet, and other smart device applications, and application program
            interfaces (collectively, the “<strong>Application</strong>”) and
            all related services (collectively, the “
            <strong>Silofit Services</strong>”). The Site, the Application, and
            Silofit Services are collectively referred to as the “
            <strong>Silofit Platform</strong>”.
          </p>
          <p>
            Silofit reserves the right to change or modify any of the terms and
            conditions in these Terms from time to time, any time, without
            notice and in its sole discretion. Any changes or modifications to
            these Terms will be effective upon changes being published on
            Silofit website or Application. Your continued use of the Silofit
            Services following the posting of any changes or modifications
            constitutes your acceptance of such changes or modifications and if
            you do not agree with these changes or modifications, you must
            immediately cease using the Silofit Services. You are responsible
            for regularly reviewing the most current Terms. Our Privacy Policy
            and Guest Agreement applicable to your use of the Silofit Platform
            are incorporated by reference into this agreement.
          </p>
          <p>
            <strong>1. Eligibility and Using the Silofit Services</strong>
          </p>
          <p>
            You must be at least 18 years old and able to enter into legally
            binding contracts to access and use the Silofit Platform or register
            for a Silofit Account. Silofit Spaces (which refer to Silofit’s
            physical locations, including common areas, access areas, as
            applicable, and are accessible via the Application, the Site, or any
            other means), are not available to persons under the age of 18. By
            using the Site, Application or Silofit Spaces, you represent and
            warrant that you are at least 18 years old and have the legal
            capacity and authority to enter into a contract. Your participation
            in using the Silofit Platform or the Silofit Spaces is for your
            sole, personal use and not for commercial purposes. You may not
            authorize others to use your user name and/or password, and you may
            not assign or otherwise transfer your user account and/or password
            to any other person or entity. When using the Silofit Platform or
            Silofit Spaces, you agree to comply with all applicable laws of the
            the country, state, province and city in which you are present while
            using the Silofit Platform or Silofit Spaces.
          </p>
          <p>
            <strong>Terms Specific for Trainers</strong>
          </p>
          <p>
            If you are a personal trainer, you must have a valid professional
            liability (personal trainer) insurance policy, with a minimum
            general liability coverage of $2,000,000 in order to sign up for a
            trainer Account and to use the Silofit Spaces as a trainer.
            Insurance policy must be in force at the time you register for an
            Account and must be kept in force at all times during your use of
            the Silofit Services. Silofit reserves the right to request a copy
            of a trainer’s insurance policy at any time and any additional
            information we may require.
          </p>
          <p>
            As further described in the Privacy Policy, Silofit uses a
            third-party payment processor (the “
            <strong>Payment Processor</strong>”) to link your credit card
            account to the Site and the Application. The processing of payments
            or credits, as applicable, in connection with your use of the Site,
            Application and Silofit Spaces will be subject to the terms,
            conditions and privacy policies of the Payment Processor and your
            credit card issuer in addition to this Agreement. Silofit is not
            responsible for any errors by the Payment Processor.
          </p>
          <p>
            By using the Silofit Services, you agree to be bound by and comply
            with any additional terms, conditions and policies provided by the
            owner, tenant, and property manager of the Silofit Spaces
            (collectively, the “<strong>Property Managers</strong>”) relating to
            the use of a specific Silofit Space(s), including compliance with
            building security procedures, IT access and use procedures provided
            by the Property Manager (the “<strong>Manager’s Policies</strong>”).
            The Manager’s Policies may be provided in electronic format through
            the Application, Site or in hardcopy format at the Silofit Spaces.
          </p>
          <p>
            Some components of the Silofit Platform implement Google Maps/Earth
            mapping services, including Google Maps API(s). Your use of Google
            Maps/Earth is subject to the Google Maps/Google Earth Additional{' '}
            <a
              target="_blank"
              href="https://www.google.com/help/terms_maps.html"
            >
              Terms of Service
            </a>
            . If you access or download the Application from the Apple App
            Store, you agree to{' '}
            <a
              target="_blank"
              href="http://www.apple.com/legal/internet-services/itunes/appstore/dev/stdeula/"
            >
              Apple’s Licensed Application End User License Agreement
            </a>
            .
          </p>
          <p>
            <strong>2. Account Registration</strong>
          </p>
          <p>
            In order to be able to use the Site, Application or the Silofit
            Spaces you first need to sign up for an account with Silofit, which
            you can do through the Site or through the Application. When signing
            up, you will be asked to provide Silofit with your personal
            information, mobile telephone number and credit card information
            (which will be held and processed by our third party Payment
            Processor further details of which are set out in the paragraph
            above and in our Privacy Policy). Once you complete your sign-up
            with Silofit, you will be provided with a personal account (an “
            <strong>Account</strong>”). You agree to maintain complete,
            accurate, and up-to-date information in your Account. Your failure
            to maintain complete, accurate, and up-to-date Account information,
            including having an invalid or expired credit card on file, may
            result in your inability to access and use the Site, Application or
            the Silofit Spaces. If you provide information that is untrue,
            inaccurate, not current or incomplete, or if Silofit has reasonable
            grounds to suspect that such information is untrue, inaccurate, not
            current or incomplete, Silofit reserves the right to suspend or
            terminate your Account and refuse any and all current or future use
            of the Silofit Services. You may not use the Silofit Services for
            any illegal purpose or in any manner inconsistent with these Terms.
          </p>
          <p>
            You are responsible for all activity that occurs with respect to
            your Account, including, without limitation, use of the Site,
            Application, and Silofit Spaces. Without limiting the foregoing, for
            any reservation made through your Account, if other persons are
            present in the Silofit spaces, you hereby agree to be fully
            responsible and to indemnify Silofit for any violation of these
            Terms or applicable laws, by-laws or regulations, even if such
            violation was caused by such other persons.
          </p>
          <p>
            You acknowledge that Silofit may collect and store Your personal
            information and behavior within the Platform, and You hereby consent
            to such collection and storage.
          </p>
          <p>
            You acknowledge and agree that Silofit: 1) may preserve Content; and
            2) may also disclose Content if required to do so by law or in the
            good faith belief that such preservation or disclosure is reasonably
            necessary to: (a) comply with legal process, applicable laws or
            government requests; (b) enforce these Terms; (c) respond to claims
            that any Content violates the rights of third parties; or (d)
            protect the rights, property, or personal safety of Silofit, its
            users and the public.
          </p>
          <p>
            <strong>3. Silofit House Rules</strong>
          </p>
          <p>By using the Silofit Services, you agree that:</p>
          <p>
            · You will not use the Silofit Platform and Silofit Spaces for
            unlawful or illegal purposes.
            <br />· You will not use the Site, Silofit Spaces or Application for
            any inappropriate purposes, including but not limited to drug use,
            alcohol abuse, gambling or prostitution, pornography, sexual
            activity, violent or threatening behavior, skateboarding, biking,
            roller-skating, roller blading, or any other purpose reasonably
            likely to reflect negatively on Silofit or any Building Owner.
            <br />·{' '}
            <strong>
              It is explicitly prohibited to smoke or vape in the Silofit
              Spaces.
            </strong>
            <br />· You will not light or allow any candles, incense sticks or
            naked flames in the Silofit Spaces.
            <br />· You will not make excessive noise in the Silofit Spaces, nor
            create any noise nor conduct any other activity which would in
            Silofit’s or any Building Owner’s judgment disturb other Silofit
            clients, guests, or other tenants in any building containing a
            Silofit Space.
            <br />· You will not bring or keep any animals in the Silofit
            Spaces, except for service animals, which shall at all times be
            harnessed or leashed.
            <br />· You will follow all additional rules, regulations, and
            by-laws regarding the Silofit Spaces, as may be communicated through
            the Application, Site, posted signs, or otherwise.
            <br />· You will not use the Site, Application or Silofit Spaces for
            sending or storing any unlawful material or for fraudulent purposes.
            <br />· You will not install, remove or modify any fixtures,
            equipment, machinery or appliances in the Silofit Spaces.
            <br />· You are responsible for leaving the Silofit Spaces in a
            clean and tidy condition.
            <br />· Neither Silofit nor any Building Owner is responsible for
            any property you may leave behind in a Silofit Space. It is your
            responsibility to ensure that you have taken all of your personal
            belongings when leaving a Silofit Space.
            <br />· You may be held liable for any costs to repair any damage to
            the Silofit Spaces and items therein during your use of the Silofit
            Space.
            <br />· You may be held liable and will be charged by Silofit for
            any items that are taken from the Silofit Spaces.
            <br />· You will not use the Site, Silofit Spaces or Application to
            cause nuisance, harassment, or inconveniences to other users,
            Building Owners, tenants or others.
            <br />· You will not copy, or distribute the Site, Application or
            other content without written permission from Silofit.
            <br />· You will not try to harm the Site, Silofit Spaces or
            Application in any way whatsoever.
            <br />· You will provide Silofit and/or the Building Owner with
            whatever proof of identity we/they may reasonably request.
            <br />· When using the internet within the Silofit Spaces, you shall
            only use it for lawful purposes, and without restricting the
            foregoing, you shall not use it for purposes of illegal or criminal
            acts, consumer fraud, etc.
            <br />· All users must wear clothing appropriate to the building's
            situation while using the Silofit Spaces.
            <br />· There is a capacity limit as to the number of persons
            permitted in a Silofit Space at any given time, and that such limit
            shall be posted on the Application or Site, and you hereby agree to
            adhere to such capacity limit.
            <br />· You hereby consent to the use of sensors by Silofit to track
            the location of various items in the Silofit Spaces, which (without
            limitation) shall be used by Silofit to optimize the Silofit Spaces.
          </p>
          <p>
            If you feel that any Silofit member or person in a Silofit Space
            that you interact with, whether online or in person, is acting or
            has acted inappropriately, including but not limited to, anyone who
            (i) engages in offensive, violent or sexually inappropriate
            behavior; (ii) you suspect of stealing from you, or (iii) engages in
            any other disturbing conduct, you should immediately report such
            person to the appropriate authorities and then to Silofit with your
            police station and report number (if available), provided that your
            report will not obligate us to take any action beyond that required
            by law, if any, or cause us to incur any liability.
          </p>
          <p>
            You further acknowledge that your use of the Silofit Spaces does not
            constitute Silofit or the Building Owner granting you a lease, but
            is rather a limited, revocable, non-exclusive, non-transferable
            contractual license to use, subject to the terms of this Agreement.
            In its sole discretion and without limiting any of Silofit’s other
            rights hereunder, Silofit may restrict your access to the Silofit
            Spaces in the event of fraud, trespassing, or violation of this
            Agreement.
          </p>
          <p>
            <strong>4. Damages, Repair or Cleaning Fees</strong>
          </p>
          <p>
            As a user of a Silofit Space, you are responsible for leaving the
            Silofit Space (including any property in the Silofit Space) in the
            condition it was in when you arrived. You are responsible for your
            own acts and omissions and are also responsible for the acts and
            omissions of any individuals whom you invite to, or otherwise
            provide access to, the Silofit Space. You are responsible for the
            cost of repair for damage to, or necessary cleaning of, Silofit
            Spaces resulting from your violation of this Agreement or your use
            of the Site, Application, or Silofit Spaces in excess of normal
            “wear and tear.” If Silofit, in its reasonable discretion,
            determines that excessive repair or cleaning is required, Silofit
            reserves the right to charge the payment method designated in your
            Account for the reasonable cost of such repair and/or cleaning, as
            well as an additional service fee which shall not exceed in Canada
            $500 Canadian Dollars, in the United States of America $500 US
            Dollars per occurrence. Any such amounts are non-refundable and at
            the reasonable discretion of Silofit.
          </p>
          <p>
            <strong>5. Payment Terms</strong>
          </p>
          <p>
            Any fees which Silofit may charge you for the use of the Site,
            Application or Silofit Spaces, will be as set out on the Site or
            Application at the time of purchase, are inclusive of any taxes
            payable, are due immediately and are non-refundable. This no refund
            policy shall apply at all times regardless of your decision to
            terminate your usage, Silofit's decision to terminate your usage,
            disruption caused to our Site, Application or Silofit Spaces either
            planned, accidental or intentional, or any reason whatsoever.
            Silofit reserves the right to determine final prevailing pricing.
          </p>
          <p>
            You do hereby agree that you shall be charged fees based on the
            length of time of your booking of the Silofit Spaces (regardless if
            you only make use of the Silofit Spaces for less than your booked
            time). If you exceed the amount of time for which you booked a
            Silofit Space, then you shall be charged for such excess time, as
            well as an overage charge if such excess time conflicts with another
            user’s booked time. You do hereby consent to such excess time
            charges and to such overage charge.
          </p>
          <p>
            Silofit may make promotional offers to any of our customers. These
            promotional offers, unless made to you, shall have no bearing
            whatsoever on your offer or contract. Silofit may change the fees
            for the Site, Silofit Spaces or Application at our sole discretion.
            We encourage you to check back at our Site periodically to find out
            about how we charge for the Site, Silofit Spaces or Application.
          </p>
          <p>
            If a credit card charge is declined, we will notify you to provide a
            valid replacement. Failure to provide a replacement within 48 hours
            may result in the suspension of your rights from accessing the
            Silofit Services. If a payment is declined, refunded, cancelled or
            charged back by your credit card issuing bank, or another person,
            you are not entitled to the return of any associated service fees
            (such as payment processing fees), irrespective of the reason for
            such decline, refund, cancellation or charge back. Silofit reserves
            the right, at its sole and utter discretion, to review and refuse a
            payment or the processing of a payment if it suspects any fraudulent
            activity or for any reason whatsoever. If you access the Site via
            your mobile phone (through the Application, for example), please be
            aware that your carrier's normal rates will still apply.
          </p>
          <p>
            <strong>6. License, Restrictions and Copyright Policy</strong>
          </p>
          <p>
            Subject to your compliance with these Terms, Silofit grants you a
            limited, non-exclusive, non-transferable and revocable license to
            use the Silofit Services. Should you choose to download content from
            the Silofit Services, you must do so in accordance with these Terms.
            Such content is provided to you for its intended purposes only and
            remains at all times the property of Silofit.
          </p>
          <p>
            You will not use, copy, adapt, decompile, modify, reverse engineer,
            prepare derivative works from, distribute, license, sell, transfer,
            publicly display, publicly perform, transmit, stream, broadcast or
            otherwise exploit the Site, Silofit Spaces, Application or any
            content, except as expressly permitted in these Terms. No licenses
            or rights are granted to you by implication or otherwise under any
            intellectual property rights owned or controlled by Silofit or its
            licensors, except for the licenses and rights expressly granted in
            these Terms or expressly agreed in writing by Silofit.
          </p>
          <p>
            <strong>7. User Content</strong>
          </p>
          <p>
            Silofit may, in its sole discretion, permit users of the Silofit
            Services to post, upload, publish, submit or transmit content. You
            are solely responsible for all content that you upload, email, post
            or otherwise transmit via or to the Site, video, images,
            information, data, text, software, music, sound, photographs,
            graphics, messages or other materials (“
            <strong>User Content</strong>”). By making available any User
            Content on or through the Site, Silofit Spaces or Application, you
            hereby grant to Silofit a worldwide, irrevocable, perpetual,
            non-exclusive, transferable, royalty-free license, sublicenseable,
            transferable right and irrevocable license, to use, view, copy,
            adapt, modify, distribute, license, sell, transfer, publicly
            display, publicly perform, transmit, stream, broadcast and otherwise
            exploit such User Content including but without limitation on,
            through or by means of the Site, Silofit Spaces or Application. In
            connection herewith, you hereby renounce and waive in favour of
            Silofit any moral rights you have or might have, now or in the
            future, with respect to User Content. Nothing in these Terms will be
            deemed to restrict any rights that you may have to use and exploit
            any User Content. You also hereby represent and warrant that you
            have the right to grant us this right over your User Content, and
            that you will indemnify us for any loss resulting from a breach of
            this warranty and defend us against claims resulting from same.
          </p>
          <p>
            You acknowledge and agree that you are solely responsible for all
            User Content that you make available through the Site, Silofit
            Spaces or Application. Accordingly, you represent and warrant that:
            (i) you either are the sole and exclusive owner of all User Content
            that you make available through the Site, Silofit Spaces or
            Application or you have all rights, licenses, consents and releases
            that are necessary to grant to Silofit the rights in such User
            Content, as contemplated under this Agreement; and (ii) neither the
            User Content nor your posting, uploading, publication, submission or
            transmittal of the User Content or Silofit’s use of the User Content
            (or any portion thereof) will infringe, misappropriate or violate a
            third party’s patent, copyright, trademark, trade secret, moral
            rights or other intellectual property rights, or rights of publicity
            or privacy, or result in the violation of any applicable law or
            regulation.
          </p>
          <p>
            You agree to not use the Silofit Platform to:
            <br />· interfere with or disrupt the Silofit Platform or servers or
            networks connected to the Silofit Platform, or disobey any
            requirements, procedures, policies or regulations of networks
            connected to the Silofit Platform;
            <br />· violate any applicable local, provincial, national or
            international law, or any regulations having the force of law;
            <br />· violate any patent, trademark, trade secret, copyright,
            right to privacy, publicity or other proprietary right of any party;
            <br />· obtain or attempt to access or otherwise obtain any
            materials or information through any means not intentionally made
            available or provided for through the Silofit Platform;
            <br />· defame, abuse, harass, stalk, threaten or otherwise violate
            the legal rights (such as rights of publicity or privacy) of others;
            <br />· send or otherwise make available any inappropriate Content,
            including profane, defamatory, obscene, abusive, racist, indecent,
            or unlawful Content;
            <br />· impersonate any person or entity or make any false
            statements regarding any agency or affiliation with any entity or
            create a false identity; or
            <br />· do any other thing that may create liability for, or damage
            the interests of, Silofit, Silofit partners and vendors.
          </p>
          <p>
            <strong>8. Intellectual Property</strong>
          </p>
          <p>
            All intellectual property rights in and to the Silofit Services and
            the Silofit Spaces, including, including but not limited to patents,
            copyrights, trademarks, industrial designs, trade secrets, and
            service marks, are the exclusive property of Silofit or are licensed
            to Silofit. The intellectual property rights in the Silofit Services
            and the Silofit Platform are protected by, among others, the
            Canadian Copyright Act, or by provisions prescribed by any other
            law, in Canada and abroad. All individual content, design, logos,
            widgets, articles of information, policies and other elements making
            up the Silofit Services are also copyrighted works. You agree to
            abide by all applicable patent, trademark, copyright and other laws,
            as well as any additional patent, trademark and copyright notices or
            restrictions contained in the Silofit Services. Any use of materials
            on the Silofit Platform other than as permitted in this agreement
            including reproduction, modification, distribution, or
            republication, without prior written permission of Silofit is
            absolutely prohibited. “Silofit”, the Silofit logo and other trade
            and/or service marks are the exclusive property of Silofit and may
            not be used for any of the aforementioned purposes. Silofit may
            protect the Silofit Services by technological means intended to
            prevent unauthorized use of the Silofit Services. You undertake not
            to circumvent these means. Infringement of the rights in and to the
            Silofit Services will, in and on itself, result in the termination
            of all your rights under these Terms.
          </p>
          <p>
            <strong>9. Application License</strong>
          </p>
          <p>
            Subject to your compliance with these Terms, Silofit grants you a
            limited non-exclusive, non-transferable license to download and
            install a copy of the Application on any mobile device or computer
            that you own or control and to run such copy of the Application
            solely for your own personal use. Furthermore, with respect to any
            Application accessed through or downloaded from the Apple App Store
            (App Store Sourced Application), you will use the App Store Sourced
            Application only: (i) on an Apple-branded product that runs iOS
            (Apple’s proprietary operating system software); and (ii) as
            permitted by the "Usage Rules" set forth in the Apple App Store
            Terms and Conditions. Silofit reserves all rights in and to the
            Application not expressly granted to you under this Agreement.
          </p>
          <p>
            <strong>
              Accessing and Downloading the Application from iTunes
            </strong>
          </p>
          <p>
            You acknowledge and agree that (i) these Terms/agreement is
            concluded between you and Silofit only, and not Apple, and (ii)
            Silofit, not Apple, is solely responsible for the App Store Sourced
            Application and content thereof. Your use of the App Store Sourced
            Application must comply with the Apple App Store Terms and
            Conditions, which you are responsible to review from time to time.
            You acknowledge that Apple has no obligation whatsoever to furnish
            any maintenance and support services with respect to the App Store
            Sourced Application. In the event of any failure of the App Store
            Sourced Application to conform to any applicable warranty, you may
            notify Apple, and Apple will refund the purchase price for the App
            Store Sourced Application to you and to the maximum extent permitted
            by applicable law, Apple will have no other warranty obligation
            whatsoever with respect to the App Store Sourced Application. As
            between Silofit and Apple, any other claims, losses, liabilities,
            damages, costs or expenses attributable to any failure to conform to
            any warranty will be the sole responsibility of Silofit.
          </p>
          <p>
            You and Silofit acknowledge that, as between Silofit and Apple,
            Apple is not responsible for addressing any claims you have or any
            claims of any third party relating to the App Store Sourced
            Application or your possession and use of the App Store Sourced
            Application, including, but not limited to: (i) product liability
            claims; (ii) any claim that the App Store Sourced Application fails
            to conform to any applicable legal or regulatory requirement; and
            (iii) claims arising under consumer protection or similar
            legislation.
          </p>
          <p>
            You and Silofit acknowledge and agree that Apple, and Apple’s
            subsidiaries, are third party beneficiaries of this Agreement as
            related to your license of the App Store Sourced Application, and
            that, upon your acceptance of the terms and conditions of this
            Agreement, Apple will have the right (and will be deemed to have
            accepted the right) to enforce this Agreement as related to your
            license of the App Store Sourced Application against you as a third
            party beneficiary thereof.
          </p>
          <p>
            Without limiting any other terms of this Agreement, you must comply
            with all applicable third party terms of agreement when using the
            App Store Sourced Application.
          </p>
          <p>
            You shall not (i) license, sublicense, sell, resell, transfer,
            assign, distribute or otherwise commercially exploit or make
            available to any third party the Site, Silofit Spaces or the
            Application in any way; (ii) modify or make derivative works based
            upon the Site, Silofit Spaces or the Application; (iii) create
            Internet "links" to, or "frame" or "mirror" the Site or Application
            on any other server or wireless or Internet-based device; (iv)
            reverse engineer the Site or Application, or access the Site or
            Application in order to (a) build a competitive product or service,
            (b) build a product using similar ideas, features, functions or
            graphics of the Site, Silofit Spaces or Application, or (c) copy any
            ideas, features, functions or graphics of the Site, Silofit Spaces
            or Application, or (v) launch an automated program or script,
            including, but not limited to, web spiders, web crawlers, web
            robots, web ants, web indexers, bots, viruses or worms, or any
            program which may make multiple server requests per second, or
            unduly burdens or hinders the operation and/or performance of the
            Site, Silofit Spaces or Application.
          </p>
          <p>
            You shall not: (i) send spam or otherwise duplicative or unsolicited
            messages in violation of applicable laws; (ii) send or store
            infringing, obscene, threatening, libelous, or otherwise unlawful or
            tortious material, including material harmful to children or that
            violate third party privacy rights and material that could be
            considered as sexual or psychological harassment or of a
            discriminatory nature; (iii) send or store material containing
            software viruses, worms, Trojan horses or other harmful computer
            code, files, scripts, agents or programs; (iv) interfere with or
            disrupt the integrity or performance of the Site, Application or
            Silofit Spaces or the data contained therein; or (v) attempt to gain
            unauthorized access to the Site, Application or Silofit Spaces or
            its related systems or networks.
          </p>
          <p>
            Silofit will have the right to investigate and prosecute violations
            of any of the above to the fullest extent of the law. Silofit may
            involve and cooperate with law enforcement authorities in
            prosecuting users who violate these Terms. You acknowledge that
            Silofit has no obligation to monitor your access to or use of the
            Site, Silofit Spaces, Application or User Content or to review or
            edit any User Content, but has the right to do so for the purpose of
            operating the Site, Silofit Spaces and Application, to ensure your
            compliance with this Agreement, or to comply with applicable law or
            the order or requirement of a court, administrative agency or other
            governmental body. Silofit reserves the right, at any time and
            without prior notice, to remove or disable access to any User
            Content that Silofit, at its sole discretion, considers to be in
            violation of this Agreement or otherwise harmful to the Site,
            Silofit Spaces or Application.
          </p>
          <p>
            <strong>10. SMS Messaging</strong>
          </p>
          <p>
            Subject to obtaining your consent, Silofit may send you SMS messages
            in relation to your bookings and use of Silofit Spaces and for
            promotional purposes. Message and data rates may apply.
          </p>
          <p>
            If you change your mobile phone service provider, you might have to
            re-enroll with Silofit in order to receive future SMS messages.
            Silofit reserves the right to stop sending SMS messages at any time;
            you may opt-out at any time by replying from your mobile phone to
            any text from Silofit with the word STOP, which opt-out will become
            effective within seven (7) days. It is important to note that opting
            out of SMS messaging may prevent you from receiving information
            necessary to properly use the Silofit service, such as room access
            codes and other important information.
          </p>
          <p>
            <strong>11. Third Party Services</strong>
          </p>
          <p>
            During use of the Site, Application and Silofit Spaces, you may
            enter into correspondence with, purchase goods and/or services from,
            or participate in promotions of third party service providers,
            advertisers or sponsors showing their goods and/or services through
            the Site, Application or Silofit Spaces. Any such activity, and any
            terms, conditions, warranties or representations associated with
            such activity, is solely between you and the applicable third party.
            Silofit and its licensors shall have no liability, obligation or
            responsibility for any such correspondence, purchase, transaction,
            services or promotion between you and any such third party. Silofit
            does not endorse any sites on the Internet that are linked through
            the Site, Silofit Spaces or Application, and in no event shall
            Silofit or its licensors be responsible for any content, products,
            services or other materials on or available from such sites or third
            party providers. Silofit provides the Site, Application and Silofit
            Spaces to you pursuant to the terms and conditions of this
            Agreement. You recognize, however, that certain third party
            providers of goods and/or services may require your agreement to
            additional or different terms and conditions prior to your use of or
            access to such goods or services, and Silofit disclaims any and all
            responsibility or liability arising from such agreements between you
            and the third party providers.
          </p>
          <p>
            Silofit may rely on third party advertising and marketing supplied
            through the Site, Application or Silofit Spaces and other mechanisms
            to subsidize the Site, Application or Silofit Spaces. By agreeing to
            these terms and conditions you agree to receive such advertising and
            marketing. If you do not want to receive such advertising, you must
            notify us in writing. Silofit reserves the right to charge you a
            higher fee for the Site, Silofit Spaces or Application, as the case
            may be, should you choose not to receive these advertising services.
            This higher fee, if applicable, will be posted on Silofit's Site
            located at http://Silofit.com. Silofit may compile, release and
            disclose non-identifiable information regarding you and your use of
            the Site, Application or Silofit Spaces as part of a customer
            profile or similar report or analysis, including, without limitation
            to third party service providers. You agree that it is your
            responsibility to take reasonable precautions in all actions and
            interactions with any third party you interact with through the
            Silofit Spaces.
          </p>
          <p>
            <strong>12. Indemnity</strong>
          </p>
          <p>
            You agree to release, defend, indemnify and hold harmless Silofit,
            applicable Building Owners, and their respectable officers,
            directors, agents, subsidiaries, joint ventures, employees and
            third-party service providers, from all claims, demands, losses,
            liabilities, costs, expenses, obligations and damages of every kind
            and nature, known and unknown, including reasonable legal fees,
            arising out of (1) your use of and access to the Site and the
            Silofit Services; (2) your violation of any term of these Terms; (3)
            your violation of any law or the rights of a third party (including,
            without limitation, any copyright, property or privacy right); or
            (4) any claim that any content you submitted caused damage to a
            third party. You agree to notify Silofit and in writing of such
            claim. If a claim subject to intellectual property infringement
            indemnification is made, Silofit shall have the right to, at its
            option, either: (i) obtain for you the right to continue using the
            Silofit Services or (ii) replace or modify the Silofit Services so
            that such Silofit Services becomes non-infringing and still provide
            substantially the same functionality for the user. This
            indemnification obligation will survive the termination of these
            Terms and your use of the Site and the Silofit Services.
          </p>
          <p>
            <strong>13. Disclaimer</strong>
          </p>
          <p>
            SILOFIT PROVIDES THE SILOFIT SERVICES, THE SILOFIT SPACES, THE SITE
            AND ITS CONTENT, MATERIALS, INFORMATION, SOFTWARE AND PRODUCTS
            INCLUDED THEREIN INCLUDING SEPARATE SERVICES PERFORMED BY THIRD
            PARTIES UNDER CONTRACT FOR USE ON AN “AS IS”, “WHERE IS” AND “AS
            AVAILABLE” BASIS. TO THE MAXIMUM EXTENT PERMITTED BY LAW, SILOFIT
            DISCLAIMS ALL REPRESENTATIONS AND WARRANTIES WHETHER EXPRESS OR
            IMPLIED, WITH RESPECT TO THE SILOFIT SERVICES, INCLUDING WITHOUT
            LIMITATION, (I) ANY WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A
            PARTICULAR PURPOSE, FEATURES, QUALITY, NON-INFRINGEMENT, TITLE,
            PERFORMANCE, COMPATIBILITY, SECURITY OR ACCURACY; AND THAT THE
            QUALITY OF THE SILOFIT SPACES, AS WELL AS ANY PRODUCTS, SERVICES,
            INFORMATION, OR OTHER MATERIAL PURCHASED OR OBTAINED BY YOU THROUGH
            THE SITE OR APPLICATION WILL MEET YOUR REQUIREMENTS OR EXPECTATIONS.
            YOU ACKNOWLEDGE AND AGREE THAT THE ENTIRE RISK ARISING OUT OF YOUR
            USE OF THE SITE, APPLICATION AND SILOFIT SPACES, AND ANY THIRD PARTY
            SERVICES OR PRODUCTS REMAINS SOLELY WITH YOU, TO THE MAXIMUM EXTENT
            PERMITTED BY LAW.
          </p>
          <p>
            YOU HEREBY ACKNOWLEDGE AND AGREE THAT: (A) THE LOCKING MECHANISM
            USED ON THE SILOFIT SPACES IS PROVIDED BY A THIRD PARTY, AND SILOFIT
            AND BUILDING OWNERS SHALL HAVE NO LIABILITY WHATSOEVER WITH RESPECT
            TO ANY FAILURE OF THE LOCKING SYSTEM TO WORK IN ITS INTENDED MANNER,
            INCLUDING WITHOUT LIMITATION ANY LOSS OR THEFT OF YOUR PROPERTY; (B)
            THE SILOFIT SPACES ARE LOCATED IN BUILDINGS WHICH ARE NOT OWNED OR
            MANAGED BY SILOFIT, AND SILOFIT MAKES NO REPRESENTATIONS OR
            WARRANTIES RELATING THERETO. ADDITIONALLY, SILOFIT AND BUILDING
            OWNERS MAKE NO REPRESENTATION OR WARRANTY WITH RESPECT TO THE
            SUITABILITY OF ANY SILOFIT SPACE FOR ANY PARTICULAR ACTIVITY NOR ANY
            ACTIVITIES THAT ARE CARRIED ON IN THE SILOFIT SPACES, AND SHALL NOT
            BE LIABLE IN ANYWAY FOR SUCH ACTIVITIES.
          </p>
          <p>
            SILOFIT AND BUILDING OWNERS MAKE NO REPRESENTATION REGARDING THE
            WIFI ACCESSIBLE IN THE SILOFIT SPACES, INCLUDING WITH REGARD TO
            ACCESS THERETO.
          </p>
          <p>
            Silofit does not warrant that the Silofit Services will operate in
            an uninterrupted or error-free manner, or that the Silofit Services
            will always be available or free from all harmful components, or
            that it is safe, secured from unauthorized access, immune from
            damages, free of malfunctions, bugs or failures, including, but not
            limited to hardware failures, originating either from Silofit or its
            providers.
          </p>
          <p>
            <strong>14. Limitation of Liability</strong>
          </p>
          <p>
            SILOFIT ITS OFFICERS, DIRECTORS, SHAREHOLDERS, EMPLOYEES, SUPPLIERS,
            SUB-CONTRACTORS, AGENTS AND BUILDING OWNERS, WILL NOT BE LIABLE FOR
            ANY DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR ANY OTHER DAMAGE
            OR LOSS (INCLUDING LOSS OF PROFIT AND LOSS OF DATA), COSTS, EXPENSES
            AND PAYMENTS, ARISING FROM OR IN CONNECTION WITH THE USE OF, OR THE
            INABILITY TO USE THE SERVICES, OR FROM ANY FAULT, OR ERROR MADE BY
            OUR EMPLOYEES OR ANYONE ACTING ON OUR BEHALF, OR FROM YOUR RELIANCE
            ON THE CONTENT OF THE SERVICES, OR FROM ANY DENIAL OR CANCELLATION
            OF YOUR ACCOUNT, OR FROM THE RETENTION, DELETION, DISCLOSURE AND ANY
            OTHER USE OR LOSS OF YOUR CONTENT ON THE SERVICES. IN NO EVENT SHALL
            SILOFIT’S, ITS LICENSORS’ OR ANY BUILDING OWNER’S AGGREGATE
            LIABILITY ARISING OUT OF OR RELATING TO THIS AGREEMENT OR YOUR USE
            OF (OR INABILITY TO USE) A SILOFIT SPACE, THE SITE OR APPLICATION
            EXCEED THE AMOUNTS ACTUALLY PAID BY YOU TO SILOFIT IN THE SIX (6)
            MONTH PERIOD IMMEDIATELY PRECEDING THE EVENT GIVING RISE TO SUCH
            CLAIM OR THE AMOUNT OF FIFTY CANADIAN DOLLARS (50$) (IF THE
            LIABILITY ARISES IN CANADA), OR FIFTY US DOLLARS (IF THE LIABILITY
            ARISES IN THE US), WHICHEVER IS GREATER. YOU UNDERSTAND AND AGREE
            THAT YOU OBTAIN OR OTHERWISE DOWNLOAD CONTENT, MATERIAL OR DATA
            THROUGH OR FOR THE USE OF THE SILOFIT SERVICES AT YOUR OWN
            DISCRETION AND RISK AND THAT YOU WILL BE SOLELY RESPONSIBLE FOR ANY
            DAMAGES TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM
            THE DOWNLOAD OF SUCH MATERIAL OR DATA. IN ADDITION, SILOFIT DOES NOT
            REPRESENT OR WARRANT THAT THE INFORMATION ACCESSIBLE VIA THE WEBSITE
            OR THE SERVICES IS ACCURATE, COMPLETE OR UP TO DATE.
          </p>
          <p>
            Your use of the Services may be subject to various local,
            provincial, state, national, or international laws, and you may have
            rights which vary from jurisdiction to jurisdiction. Because some
            countries do not allow limitations on warranties and/or the
            exclusion or limitation of damages, the limitations and/or
            exclusions found herein may not apply to you. Any provision found
            herein shall not be enforceable solely to the extent that it is
            prohibited by an applicable law.
          </p>
          <p>
            RESPONSIBILITY FOR THE DECISIONS YOU MAKE REGARDING SILOFIT SPACES
            OFFERED VIA THE SITE OR APPLICATION (WITH ALL ITS IMPLICATIONS)
            RESTS SOLELY WITH YOU. SILOFIT WILL NOT ASSESS THE SUITABILITY,
            LEGALITY OR ABILITY OF ANY SUCH BUILDING OWNERS OR OTHER THIRD
            PARTIES AND YOU EXPRESSLY WAIVE AND RELEASE SILOFIT AND BUILDING
            OWNERS, TO THE MAXIMUM EXTENT ALLOWABLE UNDER APPLICABLE LAW, FROM
            ANY AND ALL LIABILITY, CLAIMS, CAUSES OF ACTION, OR DAMAGES ARISING
            FROM YOUR USE OF THE SITE, APPLICATION OR SILOFIT SPACES, OR IN ANY
            WAY RELATED TO THE THIRD PARTIES INTRODUCED TO YOU BY THE SITE,
            APPLICATION OR SILOFIT SPACES.
          </p>
          <p>
            THE QUALITY OF THE BUILDINGS IN WHICH THE SILOFIT SPACES ARE LOCATED
            ARE ENTIRELY THE RESPONSIBILITY OF THE BUILDING OWNERS. YOU
            UNDERSTAND THAT BY USING THE SITE, APPLICATION AND THE SILOFIT
            SPACES, YOU MAY BE EXPOSED TO LOCATIONS THAT ARE POTENTIALLY
            DANGEROUS, OFFENSIVE, HARMFUL TO MINORS, UNSAFE OR OTHERWISE
            OBJECTIONABLE, AND THAT YOU USE THE SITE, APPLICATION AND THE
            SILOFIT SPACES AT YOUR OWN RISK.
          </p>
          <p>
            TO THE EXTENT APPLICABLE UNDER LOCAL LAW, NOTHING IN THIS AGREEMENT
            SHALL LIMIT SILOFIT'S LIABILITY FOR (A) DEATH OR PERSONAL INJURY
            CAUSED BY SILOFIT'S NEGLIGENCE, OR (B) FOR FRAUD OR FRAUDULENT
            MISREPRESENTATION.
          </p>
          <p>
            <strong>15. Rating System</strong>
          </p>
          <p>
            You may be required to rate certain aspects of your overall
            experience of the Silofit Spaces, which rating will be prompted
            through the Site or Application. If you do not participate in such
            rating when requested, Silofit reserves the right (without limiting
            any of Silofit’s other rights hereunder) to restrict your access to
            the Silofit Spaces.Additionally, if Silofit, or if other users, rate
            your usage of the Silofit Spaces as being below a threshold
            acceptable to Silofit, then we may in our sole discretion, and
            without limiting any of Silofit’s other rights hereunder, restrict
            your access to the Silofit Spaces.
          </p>
          <p>
            <strong>16. Notices</strong>
          </p>
          <p>
            Silofit may send you notices by means of email to your email address
            on record in Silofit's account information, or by written
            communication sent by first class mail or pre-paid post to your
            address on record in Silofit's account information. You may give
            notice, and address any complaint or claim to Silofit (such notice,
            complaint or claim shall be deemed given when received by Silofit)
            at any time by means of email to info@Silofit.com.
          </p>
          <p>
            <strong>17. Applicable Law</strong>
          </p>
          <p>
            These Terms are governed by and construed in accordance with the
            laws of the Province of Quebec and the laws of Canada applicable
            therein, without regard to the conflicts of laws provisions thereof.
            The parties attorn to the jurisdiction of the courts of the district
            of Montreal, Province of Quebec.
          </p>
          <p>
            <strong>18. Termination</strong>
          </p>
          <p>
            You agree that Silofit, in its sole discretion and for any or no
            reason, may terminate any member or customer account (or any part
            thereof) you may have with Silofit or your use of Silofit Space, and
            remove and discard all or any part of your account or any of your
            User Content, at any time. Silofit may also in its sole discretion
            and at any time discontinue providing access to the Site,
            Application and Silofit Spaces, or any part thereof, with or without
            notice. You agree that any termination of your access to the Site,
            Silofit Space, Application or any account you may have or portion
            thereof may be effected without prior notice, and you agree that
            Silofit shall not be liable to you. These remedies are in addition
            to any other remedies Silofit may have at law or in equity.
          </p>
          <p>
            You may terminate your Account at any time by deleting the
            Application from your device, ceasing all use of the Site and
            Silofit Spaces and requesting Silofit to cancel your account via
            email sent to info@Silofit.com.
          </p>
          <p>
            <strong>19. General</strong>
          </p>
          <p>
            No joint venture, partnership, employment, or agency relationship
            exists between you, Silofit or any third party provider as a result
            of these Terms or use of the Site, Silofit Spaces or Application.
            This Terms may not be assigned by you (whether in whole or in part)
            without the prior written approval of Silofit. These Terms may be
            assigned without your consent (in whole or in part) by Silofit,
            including to (i) a parent or subsidiary, (ii) an acquirer of assets,
            or (iii) a successor by merger. Any purported assignment in
            violation of this section shall be void. If any provision is held to
            be invalid or unenforceable, such provision shall be struck and the
            remaining provisions shall be enforced to the fullest extent under
            law. The failure of Silofit to enforce any right or provision in
            these Terms shall not constitute a waiver of such right or provision
            unless acknowledged and agreed to by Silofit in writing. These Terms
            comprise the entire agreement between you and Silofit and supersedes
            all prior or contemporaneous negotiations, discussions or
            agreements, whether written or oral, between the parties regarding
            the subject matter contained herein.
          </p>
          <p>
            <strong>20. Disclaimer and assumption of risk</strong>
            <p>
              You declare that you have voluntarily chosen to use the space made
              available by Silofit Inc. (“<strong>Silofit”</strong>). In doing
              so, you understand, acknowledges and agrees that, despite
              Silofit’s effort to provide a safe environment, the use of
              Silofit’s facility during the COVID-19 pandemic exposes you to
              risks that you may impact your physical and psychological health
              (and that of your family and those that come into contact with
              you). Specifically, the risks include contracting the virus
              causing COVID-19 which may cause serious bodily injury, including
              death, and cause, directly or indirectly, property damage and/or
              economic losses.
            </p>
            <p>
              You acknowledge to follow and respect all preventive measures
              promulgated by government authorities and Silofit to prevent the
              spread of COVID-19 and to inform Silofit immediately if you become
              aware that any person carrying the virus frequented the premises
              or that any of the Measures were breached.
            </p>
            <p>
              You acknowledge that Silofit does not make any representations or
              warranties to the effect that the premises are exempt from the
              virus causing COVID-19 or that no person infected by the virus
              causing COVID-19 has recently frequented the premises. Therefore,
              subject to applicable laws, you hereby waive and renounces in
              favour of Silofit, its officers, directors, managers, members,
              shareholders, employees and agents (collectively, “
              <strong>Silofit Parties</strong>”) to any and all liabilities,
              deficiencies, demands, claims, suits, actions, or causes of
              action, assessments, losses, costs, expenses (including reasonable
              legal fees), interest, penalties, obligations, reasonable costs or
              reasonable expenses of any and all investigations, proceedings,
              judgments and settlements (collectively, the “
              <strong>Losses</strong>”) sustained or incurred by you, directly
              or indirectly, to the virus causing COVID-19 (the “
              <strong>Released Claims</strong>”) and hereby grants to Silofit a
              complete, total and final discharge with respect to the Released
              Claims. You agree that, to the extent permitted under applicable
              laws, Silofit shall have no liability whatsoever towards you or
              any related third party, whether by contractual,
              extra-contractual, tort, or other means, for any direct, indirect,
              special, consequential, exemplary or punitive damages or for any
              lost profits or revenues, arising out of or relating to COVID-19.
            </p>
            <p>
              You undertake to take responsibility and to defend and hold
              harmless any of the Silofit Parties from and against any and all
              Losses, resulting from, arising out of or otherwise by virtue of
              (i) your failure to comply with any of the Measures or (ii) the
              undersigned’s actions.
            </p>
          </p>
          <p>© 2021 Silofit Enterprises</p>
        </div>
      </Page>
    </Privacy>
  </Layout>
);

TosPage.defaultProps = {
  path: '/terms-service/',
};

export default TosPage;
